// fairs List Item

<template>
  <transition name="fade">
    <div class="fair_list_item">
      <div class="col-10 logo">
        <img v-if="fair.icon" :src="fair.icon" :alt="fair.title || fair.name + ' logo'" @load="$eventBus.$emit('fair-image-loaded')"/>
      </div>
      <div class="col-30 title">
        <h3 class="title">{{ (fair.title || fair.name) | spaceAfterComma | truncate(40) }}</h3>
      </div>
      <div class="col-30 location">
        <span class="city">{{ fair.city }}</span><span v-if="fair.city && fair.country">, </span><span class="country" v-if="fair.country">{{ fair.country }}</span>
      </div>
      <div v-if="fair.date_to && fair.date_from" class="col-25 dates">
        {{ fair.date_from | moment("DD.MM.YYYY") }}<span v-if="fair.date_to && $moment(fair.date_to).format('x') > $moment(fair.date_from).format('x')"> - {{ fair.date_to | moment("DD.MM.YYYY") }}</span><br/>
        <ICalButton :fair="fair" type="iCal" :text="$root.l10n('ui.btn_iCal')"/>
        <ICalButton :fair="fair" type="Outlook" :text="$root.l10n('ui.btn_Outlook')"/>
      </div>
      <div v-else class="col-25 dates">
        {{ fair.period }}
      </div>
      <div class="col-5 more_info">
        <component :is="moreinfoURL ? 'a' : 'span'" :href="moreinfoURL | ensureHTTP" :target="isExternal ? '_blank' : '_self'"><i class="icon-right-circle"></i></component>
      </div>
    </div>
  </transition>
</template>

<script>
import ICalButton from '@/components/ICalButton.vue'

export default {
  name: 'ListItem',
  components: {
    ICalButton
  },
  props: [
    // fair item to display
    'fair'
  ],
  computed: {
    moreinfoURL() {
      // return this.fair['website']
      return this.fair[this.$config.moreinfo] || ''
    },
    isExternal() {
      return !this.$config.moreinfoInternal
    },
  },
}
</script>

<style scoped lang="scss">
@import '~@/styles/vars';

%col-line-height {
  line-height: 50px;

  @media screen and (max-width: 39.9375em) {
    line-height: normal;
  }
}

.fair_list_item {
  color: #213658;

  @media screen and (min-width: 39.9375em) {
    min-height: 80px;
  }

  h2, h3, h4, h5, h6, p {
    margin: 0;
  }
}

.fair_list_item:after {
  content: "";
  display: table;
  clear: both;
}

.col-10 { width: 10%; }
.col-25 { width: 25%; }
.col-30 { width: 30%; }

[class^="col"],
[class^="col-"] {
  float: left; padding: 10px 4px;

  @media screen and (max-width: 39.9375em) {
    float: none;
    margin-left: 20%;
    width: 80%;
    line-height: normal;
    padding: 4px;
  }
}

.logo {
  width: 8%;
  line-height: 1px;

  @media screen and (max-width: 39.9375em) {
    float: left;
    padding: 10px 4px;
    width: 18%;
    margin-left: 0;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.title {
  h3 {
    line-height: normal;
    font-size: 1em;
  }
}

.location {
  .city {
    font-weight: 600;
  }
}

.dates {

  a {
    color: inherit;
    font-size: calc(14 / 16 * 1rem);
  }
  i {
    color: $primary-color-alt;
    font-size: small;
    margin-right: 2px;
  }
  a + i {
    margin-left: 18px;
  }
}

.more_info {
  @extend %col-line-height;

  @media screen and (max-width: 39.9375em) {
    text-align: right;
    position: relative;
    top: -2em;
    height: 0;
    padding: 0;
  }
  a {
    color: $accent-color;
    font-size: 1.2rem;
    text-decoration: none;
    &:hover {
      color: $accent-color-hover;
    }
  }
  span {
    color: #f1f1f1;
    font-size: 1.2rem;
  }
}

</style>
