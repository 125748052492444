// iCalendar button/link; used for both GridItem and ListItem

<template>
  <span :class="$style.calendar"><a @click="download" target="_blank"><i class="icon-calendar"></i>{{ text }}</a></span>
</template>

<script>
import download from 'downloadjs'
import slugify from 'slugify'
import speakingurl from 'speakingurl'
import * as ics from 'ics'
import { ensureHTTP } from '@/common/filters'

export default {
  name: 'ICalButton',
  props: [
    // fair item to display calendar link for
    'fair',
    // type of calendar link (currently supported "iCal" and "Outlook")
    'type',
    // text to show on button/link
    'text'
  ],
  methods: {
    // prepare Event data and download of the ICS file
    download() {
      // mockup fair.description since the API does not provide one
      let openingText = this.fair.opening_hours_text.replace(/<br\/>/g, '\n')

      this.fair.description = this.fair.title || this.fair.name + ' ' + this.fair.year + '\n' +
        (this.fair.focus ? (this.fair.focus.join(', ') + '\n') : '') +
        openingText

      /* keep this commented until the *with statement* is allowed in strict mode :)
      with (this.fair) {
        const event = {
          start: this.$moment(date_from).format('YYYY-M-D-H-m').split('-'),
          end: this.$moment(date_to).format('YYYY-M-D-H-m').split('-'),
          title: this.transliterate(title || name + ' ' + year),
          description: this.transliterate(description),
          location: this.transliterate(city + ', ' + continent),
          url: website,
          categories: focus.map(this.transliterate)
        }
      } */

      // prepare the Event data
      const event = {
        start: this.$moment(this.fair.date_from + ' 22:00 Z').format('YYYY-M-D-H-m').split('-'),
        end: this.$moment(this.fair.date_to + ' 21:59 Z').format('YYYY-M-D-H-m').split('-'),
        title: this.transliterate(this.fair.title || this.fair.name + ' ' + this.fair.year),
        description: this.transliterate(this.fair.description),
        location: this.transliterate(this.fair.city + ', ' + this.fair.continent),
        url: ensureHTTP(this.fair.website),
        // geo: { lat: 40.0095, lon: 105.2669 },
        categories: this.fair.focus.map(this.transliterate),
        // status: 'CONFIRMED',
        // organizer: { name: 'Admin', email: 'Race@BolderBOULDER.com' },
        // attendees: [
        //   { name: 'Adam Gibbons', email: 'adam@example.com', rsvp: true },
        //   { name: 'Brittany Seaton', email: 'brittany@example2.org', dir: 'https://linkedin.com/in/brittanyseaton' }
        // ]
      }
      // create the Event
      ics.createEvent(event, (error, value) => {
        // on Error :
        if (error) {
          console.debug(error)
          return
        }
        // else on Success :

        // swap VCALENDAR VERSION from 2.0 to 1.0 for Outlook iCalendar file
        if (this.type === 'Outlook') {
          value = value.replace('VERSION:2.0', 'VERSION:1.0')
        }
        const fileName = slugify(this.fair.name).toLowerCase() + '-' + this.type + '.ics'
        // push the ICS file to the User
        download(value, fileName, 'text/calendar')
      })
    },
    // transliterate german special characters (ä, ö, ü, etc.) for Outlook iCalendar file
    transliterate(text) {
      return this.type === 'Outlook' ? speakingurl(text.replace(/\n/g, '@@'), {
        separator: ' ',
        maintainCase: true,
        uric: true,
        mark: true,
      }).replace(/@@/g, '\n') : text
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style module lang="scss">
@import '~@/styles/vars';

.calendar {
  display: inline-block;
  margin-right: 18px;
  a {
    color: inherit;
    font-size: calc(14 / 16 * 1rem);
    cursor: pointer;
  }
  i {
    color: $primary-color-alt;
    font-size: small;
    margin-right: 6px;
  }
}
</style>
