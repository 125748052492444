// Main container

<template>
  <div>
    <template v-if="view === 'full'">
      <TopBar :fairs_view="fairs_view" :filter_visible="filter_visible" :toggleFilter="toggleFilter" :switchView="switchView"/>
      <FilterBox v-show="filter_visible === true"/>
      <Fairs :fairs_view="fairs_view"/>
    </template>
    <template v-else>
      <FairsLite :filter="filter"/>
    </template>
  </div>
</template>

<script>
import TopBar from './Main/TopBar.vue'
import FilterBox from './Main/FilterBox.vue'
import Fairs from './Main/Fairs.vue'
import FairsLite from './Main/FairsLite.vue'

export default {
  name: 'Main',
  components: {
    TopBar,
    FilterBox,
    Fairs,
    FairsLite
  },
  data() {
    return {
      // show or hide Filter Box
      filter_visible: false,
      // show fairs in Grid or List view
      fairs_view: 'grid'
    }
  },
  props: {
    // application view, "full" or "lite"
    view: {
      type: String,
      default: 'full',
      validator: value => ['full', 'lite'].includes(value)
    },
    // filter by one or more properties
    filter: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    // toggle Filter Box visibility On or Off
    toggleFilter() {
      this.filter_visible = !this.filter_visible
    },
    // switch fairs view between Grid and List
    switchView(view) {
      this.fairs_view = view
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style module lang="scss">
</style>
