exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.FairsLite_fairs_fDgOB{position:relative\n}\n.FairsLite_fairs_fDgOB .FairsLite_fairs_view_2YpUH{width:100%\n}\n.FairsLite_fairs_fDgOB .FairsLite_next_21ktx,.FairsLite_fairs_fDgOB .FairsLite_prev_3TMV-{display:block;height:100px;line-height:100px;padding:0 6px;position:absolute;top:50%;margin-top:-50px;text-decoration:none;color:#e28e13;font-size:24px;background-color:hsla(0,0%,100%,.5);border:1px solid hsla(0,0%,91.8%,.46);z-index:2;cursor:pointer;-webkit-transition:background-color .2s ease;transition:background-color .2s ease\n}\n.FairsLite_fairs_fDgOB .FairsLite_next_21ktx:hover,.FairsLite_fairs_fDgOB .FairsLite_prev_3TMV-:hover{background:hsla(0,0%,100%,.87);-webkit-transition:background-color .4s ease;transition:background-color .4s ease\n}\n.FairsLite_fairs_fDgOB .FairsLite_prev_3TMV-{left:-20px\n}\n.FairsLite_fairs_fDgOB .FairsLite_next_21ktx{right:-20px\n}", ""]);

// exports
exports.locals = {
	"fairs": "FairsLite_fairs_fDgOB",
	"fairs_view": "FairsLite_fairs_view_2YpUH",
	"next": "FairsLite_next_21ktx",
	"prev": "FairsLite_prev_3TMV-"
};