// Filter Box, iterating state.filters

<template>
  <transition name="roll-fade">
    <div id="filter_box" class="filter" ref="filter_box">
      <div v-for="(filter, key) in state.filters" :key="key">
        <h3 class="filter_headline">{{ $root.l10n('filters.'+filter.property) }}</h3>

        <ul>
          <li v-for="(item, index) in filter.values" :key="index"
            :class="{ selected: isActive(key, item) || !hasActive(key) }"
            @click="updateFilters({key, item})"
          >
            {{ item }}
          </li>
          <li @click="updateFilters({key, item:null})"
            :class="{ selected: !hasActive(key) || hasActiveAll(key) }"
          >
            {{ $root.l10n('ui.btn_all') }}
          </li>
        </ul>
      </div>

      <div v-if="!$config.instant_filters">
        <button class="button apply" @click="apply_filters">{{ $root.l10n('ui.btn_apply_filters') }}</button>
        <button class="button reset" @click="reset_filters">{{ $root.l10n('ui.btn_reset_filters') }}</button>
      </div>
    </div>
  </transition>
</template>

<script>
import { store } from '@/store'

export default {
  name: 'FilterBox',
  data() {
    return {
      // using store pattern from store.js
      state: store.state
    }
  },
  computed: {
    // keep track of locally selected filters, before applying to the application scope
    filters() {
      return this.state.active_filters.length ? this.state.active_filters.map(filter => filter.slice()) : []
    }
  },
  methods: {
    updateFilters(filter) {
      // validate key against available ones, just in case
      if (!this.state.filters[filter.key]) {
        console.debug('key not found')
        // break flow
        return
      }

      // when no filter item is provided (user clicked on "All")
      // reset locally selected filters
      if (!filter.item) {
        // console.debug('reset')
        this.filters[filter.key].splice(0, this.filters[filter.key].length) // triger setter
        // activate instant filters if applcable
        this.instant_filters()
        // break flow
        return
      }

      // another validation
      if (!this.state.filters[filter.key].values.includes(filter.item)) {
        console.debug('item not found')
        // break flow
        return
      }

      // check if we have this filter locally and remove it (toggle to OFF)
      if (this.filters[filter.key].includes(filter.item)) {
        // console.debug('remove '+filter.item)
        this.filters[filter.key].splice(this.filters[filter.key].indexOf(filter.item), 1)
      } else {
        // otherwise, add it (toggle to ON) and sort the filters
        // console.debug('add '+filter.item)
        this.filters[filter.key].push(filter.item)
        this.filters[filter.key].sort()
      }

      // when all filters from a group are all selected one by one
      // reset locally selected filters
      if (this.filters[filter.key].length === this.state.filters[filter.key].values.length) {
        // console.debug('reset 2')
        this.filters[filter.key].splice(0, this.filters[filter.key].length) // triger setter
        // activate instant filters if applcable
        this.instant_filters()
        // break flow
        return
      }

      // if no reset took place
      // activate instant filters if applcable
      this.instant_filters()
    },
    // check if item is locally selected
    isActive(key, item) {
      return this.filters[key] && this.filters[key].includes(item)
    },
    // check if group has locally selected item
    hasActive(key) {
      return this.filters[key] && this.filters[key].length
    },
    // check if group has all items locally selected
    hasActiveAll(key) {
      return this.filters[key] && this.filters[key].length === this.state.filters[key].values.length
    },
    // "reset" button action
    reset_filters() {
      // clear all locally selected filters
      this.filters = this.state.filters.map(() => [])
      // apply filters (clear)
      this.apply_filters()
    },
    // "apply" button action
    apply_filters() {
      // transfer locally selected filters to current application filters
      // break the reactive link
      store.setActiveFilters(this.filters.slice(0))
    },
    // activate filters if applicable by $config
    instant_filters() {
      if (this.$config.instant_filters) {
        this.apply_filters()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/vars';

.filter {
  overflow: hidden;
  color: #213658;
}

.filter_headline {
  text-align: left;
  margin: 1em 0;
}
ul {
  display: flex;
  justify-content: center;
  align-content: stretch;
  flex-wrap: wrap;

  list-style: none;
  padding: 0;
  margin: -5px;
}
li {
  // flex item
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 130px; // this is based on the .main max-width

  // center vertically
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline-start: 0;
  background-color: $secondary-color;
  color: $primary-color;
  padding: 10px 5px;
  border-radius: 2px;
  cursor: pointer;
  word-break: break-word;
  margin: 5px;

  &:hover {
    background-color: $secondary-color-hover;
  }

  &.selected {
    background-color: $primary-color;
    color: $secondary-color;

    &:hover {
      background-color: $primary-color-hover;
    }
  }
}
</style>

<style scoped lang="scss" name="transitions">
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
.roll-fade-enter-active {
  transition: opacity .4s ease, max-height .4s cubic-bezier(0.895, 0.030, 0.685, 0.220); /* easeInQuart */
}
.roll-fade-leave-active {
  transition: opacity .4s ease, max-height .4s cubic-bezier(0.165, 0.840, 0.440, 1.000); /* easeOutQuart */
}
.roll-fade-enter, .roll-fade-leave-to {
  max-height: 0;
  opacity: 0;
}
.roll-fade-leave, .roll-fade-enter-to {
  max-height: 100vh;
  opacity: 1;
}
</style>
