let l10n = {
  // output string based on preset language
  // message_id can be nested with a dot separator like 'ui.btn_filter_show' or 'filters.continent'
  // if string with such ID tree is missing, output message ID for reference
  __ (messageId, lang) {
    var ids = messageId.split('.')
    var res = this.messages[lang] || {}
    for (var id of ids) {
      res = res[id] || {}
    }
    return res.length ? res : messageId
  },
  // load language strings, must be in format {lang:{}, lang:{}}
  init (messages) {
    this.messages = messages
  },
  messages: {}
}
export default l10n
