// NB: fix IE11 compatibility
// we make use of ics package that in turn uses joi package, which is not browser compatible
// so we have to alias the joi package to the joi-browser package into the webpack config
// and include this es6-shim package as well (and we might need the core-js package in the future)
// eslint-disable-next-line
import * as ES6shim from 'es6-shim'

import Vue from 'vue'
// load AJAX library
import axios from 'axios'
import VueAxios from 'vue-axios'
import vueCustomElement from 'vue-custom-element'
// custom-elements polyfill
import 'document-register-element'

// import VueI18n from 'vue-i18n'
// load our configuration
import config from '@/config'
// loac custom localization object
import l10n from '@/common/l10n'
import { messages } from './messages'
// load data formatting library
import VueMoment from 'vue-moment'
import FairFinder from '@/FairFinder.vue'
import { truncate, spaceAfterComma, ensureHTTP } from '@/common/filters'

// give templates access to the environment variables
Vue.prototype.$env = process.env
Vue.prototype.$isDevelopmentEnv = process.env.NODE_ENV === 'development'
Vue.prototype.$isProductionEnv = process.env.NODE_ENV === 'production'

// make use of AJAX library
Vue.use(VueAxios, axios)
// make use of Date format library
Vue.use(VueMoment)
Vue.use(vueCustomElement)

Vue.filter('truncate', truncate)
Vue.filter('spaceAfterComma', spaceAfterComma)
Vue.filter('ensureHTTP', ensureHTTP)

// supress developoment environment hint/warning
Vue.config.productionTip = false
// provide bus for events between components
Vue.prototype.$eventBus = new Vue() // Global event bus

// set up localization and provide global access for templates
// translations
l10n.init(messages)

Vue.prototype.$config = Object.assign({}, config)

Vue.customElement('fair-finder', FairFinder, {
  beforeCreateVueInstance (root) {
    root.methods = {
      l10n (messageId) {
        return l10n.__(messageId, root.propsData.lang)
      }
    }
    return root
  }
})
