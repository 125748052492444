export {
  truncate,
  spaceAfterComma,
  ensureHTTP,
}

function truncate(value, length = 30) {
  if (!value) return ''
  value = value.toString()

  if (value.length <= length) {
    return value
  }

  return `${value.slice(0, length)}...`
}

function spaceAfterComma(value) {
  if (!value) return ''
  value = value.toString()

  return /,\S/.test(value) // if ',' [comma] is not followed by ' ' [space]
    ? value.replace(/,/gm, ', ') // replace with [comma][space]
    : value
}

function ensureHTTP(value) {
  return (!/^https?:\/\//i.test(value) ? 'http://' + value : value)
}
