// Top Bar with action buttons - Filter, Sort, Grid/List view

<template>
  <div :class="$style.top_bar">
    <a href="#filter_box"
      v-if="state.filters.length"
      :class="[$style.button, $style.filter, { [$style.active]: filter_visible }, { [$style.has_active]: state.active_filters.some(x => x.length) }]"
      @click.prevent="toggleFilter"
    >
      {{filter_visible ? $root.l10n('ui.btn_filter_hide') : $root.l10n('ui.btn_filter_show')}}
    </a>
    <label :class="[$style.sort]">{{ $root.l10n('ui.btn_sort') }}: <a href="#sort" @click.prevent="switch_sort">{{ state.sort == 'oldest' ? $root.l10n('ui.opt_sort_oldest') : $root.l10n('ui.opt_sort_newest') }}<i class="icon-sort"></i></a></label>

    <div :class="[$style.view]">
      <a href="#fairs_view_list"
        @click.prevent="switchView('list')"
        class="view_list"
        :class="[$style.button, $style.list, { [$style.active]: fairs_view == 'list' }]"
        :aria-label="$root.l10n('ui.btn_list')"
      >
        <i class="icon-list"></i>
      </a>
      <a href="#fairs_view_grid"
        @click.prevent="switchView('grid')"
        class="view_grid"
        :class="[$style.button, $style.grid, { [$style.active]: fairs_view == 'grid' }]"
        :aria-label="$root.l10n('ui.btn_grid')"
      >
        <i class="icon-grid"></i>
      </a>
    </div>
  </div>
</template>

<script>
import { store } from '@/store'

export default {
  name: 'TopBar',
  data() {
    return {
      // using store pattern from store.js
      state: store.state
    }
  },
  props: {
    // show or hide Filter Box
    filter_visible: {
      type: Boolean,
      default: true
    },
    // show fairs in Grid or List view
    fairs_view: {
      type: String,
      default: 'grid',
      validator: value => ['grid', 'list'].includes(value)
    },
    // function to toggle Filter Box visibility On or Off
    toggleFilter: {
      type: Function,
      default: () => {}
    },
    // function to switch fairs view between Grid and List
    switchView: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    // switch Sort order to "oldest" first or "newest" first
    switch_sort() {
      store.switchSort()
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style module lang="scss">
@import '~@/styles/vars';

$height: 52px;
$bar-color: #f1f1f1;

.top_bar {
  margin: 0 auto;
  overflow: hidden;
  background-color: $bar-color;

  @media screen and (max-width: 39.9375em) {
    position: relative;
  }

  i {
    font-size: 19px;
  }
}
.button {
  display: inline-block;
  height: $height;
  border: none;
  background-color: $secondary-color-alt;
  color: $primary-color-alt;
  padding: 14px 20px;
  border-radius: 2px;
  line-height: 24px;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: -6px;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 26px 0 26px 6px;
    border-color: transparent transparent transparent $secondary-color-alt;
  }
  &:hover {
    background-color: $secondary-color-alt-hover;
    color: $primary-color-alt;

    &::after {
      border-color: transparent transparent transparent $secondary-color-alt-hover;
    }
  }
  &.active {
    background-color: $primary-color-alt;
    color: $secondary-color-alt;

    &::after {
      border-color: transparent transparent transparent $primary-color-alt;
    }
    &:hover {
      background-color: $primary-color-alt-hover;
      color: $secondary-color-alt;

      &::after {
        border-color: transparent transparent transparent $primary-color-alt-hover;
      }
    }
  }
}

.filter {
  float: left;
  padding: 14px 24px;
  position: relative;
  width: 168px;

  &.has_active::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px;
    border-color: rgba( $primary-color-alt, .5 ) transparent transparent rgba( $primary-color-alt, .5 );
  }

  &::after {
    right: -9px;
    border-width: 26px 0 26px 9px;
  }
}

.sort {
  float: left;
  padding: 14px 24px;
  line-height: 22px;

  @media screen and (max-width: 39.9375em) {
    clear: both;
  }

  a {
    text-decoration: none;
    color: inherit;
    margin-left: 6px;
    i {
      font-size: 12px;
      color: $primary-color-alt;
    }

    &:hover {
      color: $primary-color-alt;
    }
  }
}

.view {
  height: $height;
  float: right;

  @media screen and (max-width: 39.9375em) {
    position: absolute;
    right: 6px;
  }
}

.list {
  position: relative;
  z-index: 2;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 26px 0 26px 6px;
    border-color: transparent transparent transparent $bar-color;
  }

  i {
    position: relative;
    left: 4px;
  }
}
.grid {
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -6px;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 26px 0 26px 9px;
    border-color: transparent transparent transparent $primary-color-alt;
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.5));
  }

  i {
    position: relative;
    left: 2px;
  }
}
</style>
