import { render, staticRenderFns } from "./Fairs.vue?vue&type=template&id=cf9b6a74&scoped=true&"
import script from "./Fairs.vue?vue&type=script&lang=js&"
export * from "./Fairs.vue?vue&type=script&lang=js&"
import style0 from "./Fairs.vue?vue&type=style&index=0&module=true&lang=scss&"
import style1 from "./Fairs.vue?vue&type=style&index=1&id=cf9b6a74&scoped=true&lang=scss&name=transitions&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "cf9b6a74",
  null
  
)

component.options.__file = "Fairs.vue"
export default component.exports