exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.ICalButton_calendar_2Ke8F{display:inline-block;margin-right:18px\n}\n.ICalButton_calendar_2Ke8F a{color:inherit;font-size:.875rem;cursor:pointer\n}\n.ICalButton_calendar_2Ke8F i{color:#e28e13;font-size:small;margin-right:6px\n}", ""]);

// exports
exports.locals = {
	"calendar": "ICalButton_calendar_2Ke8F"
};