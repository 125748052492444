exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.List_list_2IYq5{list-style:none;padding:0;margin:0 0 22px\n}\n.List_item_39DPu{padding:10px 0;border-bottom:1px solid #f1f1f1;text-align:left\n}", ""]);

// exports
exports.locals = {
	"list": "List_list_2IYq5",
	"item": "List_item_39DPu"
};