// Block of Fairs, light version of Grid view only

<template>
  <div class="fairs" :class="[$style.fairs]">
    <a v-if="page > 1"
      :class="[$style.prev]"
      @click.prevent="page--"
    >
      <i class="icon-left"></i>
    </a>

    <FairsGrid :fairs="prepared_fairs" :class="[$style.fairs_view]" grid_view="lite"/>

    <a v-if="page * $config.fairs_per_page < state.fairs.length && page * $config.fairs_per_page < filtered_fairs.length"
      :class="[$style.next]"
      @click.prevent="page++"
    >
      <i class="icon-right"></i>
    </a>
  </div>
</template>

<script>
import FairsGrid from './Fairs/Grid.vue'
import { store } from '@/store'

export default {
  name: 'FairsLite',
  props: [
    'filter'
  ],
  data() {
    return {
      // keep track of current page for fairs pagination
      page: 1,
      // using store pattern from store.js
      state: store.state
    }
  },
  components: {
    FairsGrid
  },
  computed: {
    // fairs per page depends on fairs view (Grid or List)
    fairs_per_page() {
      return this.$config['fairs_per_page_' + this.fairs_view] || this.$config.fairs_per_page
    },
    // fair items to display, based on (hardcoded) sorting and pagination
    prepared_fairs() {
      return this.paginate(this.filteredFairs(this.sortedFairs(this.state.fairs.slice(0))), this.fairs_per_page, this.page)
    },
    // total fair items to display, based on filter only
    filtered_fairs() {
      return this.filteredFairs(this.state.fairs.slice(0))
    }
  },
  methods: {
    // sort fair items with the oldest first, newest last, using date_from property
    sortedFairs(fairs) {
      // skip if no fairs
      if (!fairs.length) return []
      return fairs.sort((a, b) => {
        // return (this.$moment(a.date_from) > this.$moment(b.date_from)) ? 1 : ((this.$moment(a.date_from) < this.$moment(b.date_from)) ? -1 : 0)
        return (this.$moment(a.sort_date) > this.$moment(b.sort_date)) ? 1 : ((this.$moment(a.sort_date) < this.$moment(b.sort_date)) ? -1 : 0)
      })
    },
    // filter fair items based on preset filter(s)
    // modified copy of Fairs.filteredFairs()
    filteredFairs(fairs) {
      // skip if no fairs
      if (!fairs.length) return []
      // simple validation
      // if no filters, return all fairs and skip
      if (!this.filter) return fairs.filter()
      // keep scope
      var vm = this
      return fairs.filter(fair => {
        var match = true
        // check for a match agains any of the provided filters
        for (var idx in vm.filter) {
          for (var filter_id in vm.filter[idx]) {
            // match = match | !vm.filter[idx][filter_id] || (typeof fair[filter_id] !== 'undefined' && fair[filter_id].includes(vm.filter[idx][filter_id]))
            match = match & (!vm.filter[idx][filter_id] || (typeof fair[filter_id] !== 'undefined' && (typeof vm.filter[idx][filter_id] === 'string' ? fair[filter_id].includes(vm.filter[idx][filter_id]) : vm.filter[idx][filter_id].filter(v => fair[filter_id].indexOf(v) !== -1).length > 0)))
          }
        }
        return match
      })
    },
    // slice portion of fair items based on the per page value for the current fairs view and the current page
    paginate(fairs, fairs_per_page, page) {
      // one page at a time
      return fairs.slice((page - 1) * fairs_per_page, page * fairs_per_page)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style module lang="scss">
@import '~@/styles/vars';

.fairs {
  position: relative;

  .fairs_view {
    width: 100%; // IE11
  }

  .prev, .next {
    display: block;
    height: 100px;
    line-height: 100px;
    padding: 0 6px;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    text-decoration: none;
    color: #e28e13;
    font-size: 24px;
    background-color: #ffffff80;
    border: 1px solid #eaeaea75;
    z-index: 2;
    cursor: pointer;
    transition: background-color .2s ease;

    &:hover {
      background: #ffffffdd;
      transition: background-color .4s ease;
    }
  }
  .prev {
    left: -20px;
  }
  .next {
    right: -20px;
  }
}
</style>
