// Block of Fairs, two possible displays - Grid and List view

<template>
  <div class="fairs" :class="[$style.fairs]">
    <transition-group name="fade" tag="div" class="fairs_group" :style="style">
      <FairsGrid v-show="fairs_view === 'grid'" :fairs="prepared_fairs" ref="fairs_view_grid" key="grid" class="fairs_view" id="fairs_view_grid" :class="[$style.fairs_view]" grid_view="full"/>
      <FairsList v-show="fairs_view === 'list'" :fairs="prepared_fairs" ref="fairs_view_list" key="list" class="fairs_view" id="fairs_view_list" :class="[$style.fairs_view]"/>
    </transition-group>
    <button v-if="page * fairs_per_page < state.fairs.length && page * fairs_per_page < filtered_fairs.length"
      :class="[$style.button, 'bg-color--primary', 'load_more']"
      @click="page++"
    >
      {{ $root.l10n('ui.btn_load_more') }}
    </button>
  </div>
</template>

<script>
import FairsGrid from './Fairs/Grid.vue'
import FairsList from './Fairs/List.vue'
import { store } from '@/store'

export default {
  name: 'Fairs',
  data() {
    return {
      // HTML element styling, used for transition
      style: { height: '10px' },
      // keep track of current page for fairs pagination
      // page: 1, --> it's computed now
      // keep track of current page for fairs pagination in Grid view only
      page_grid: 1,
      // keep track of current page for fairs pagination in List view only
      page_list: 1,
      // using store pattern from store.js
      state: store.state
    }
  },
  components: {
    FairsGrid,
    FairsList
  },
  props: [
    // show fairs in Grid or List view
    'fairs_view'
  ],
  watch: {
    prepared_fairs: function() {
      // recalculate height after fairs change
      this.$nextTick(() => {
        this.setHeight()
      })
    },
    fairs_view: function(index) {
      // recalculate height after fairs view change
      this.$nextTick(() => {
        this.setHeight(index)
      })
    }
  },
  computed: {
    // current page for fairs pagination, depending on the current view
    page: {
      get() {
        return this.fairs_view === 'grid' ? this.page_grid : this.page_list
      },
      set(value) {
        this['page_' + this.fairs_view] = value
      }
    },
    // fairs per page depends on fairs view (Grid or List)
    fairs_per_page() {
      // do we have fairs_per_page_* set up
      return this.$config['fairs_per_page_' + this.fairs_view] &&
        // and we either
        // don't have allowed_fairs_per_page_* set up
        (!this.$config['allowed_fairs_per_page_' + this.fairs_view] ||
          // or the allowed_fairs_per_page_* matches the fairs_per_page_* value
          // - literal
          this.$config['allowed_fairs_per_page_' + this.fairs_view].includes(this.$config['fairs_per_page_' + this.fairs_view]) ||
          // - or converted to integer
          this.$config['allowed_fairs_per_page_' + this.fairs_view].includes(parseInt(this.$config['fairs_per_page_' + this.fairs_view], 10)))
        // then, when the fairs_per_page_* is set to show all "All"
        ? (this.$config['fairs_per_page_' + this.fairs_view] === 'all'
          // return all items count
          ? this.state.fairs.length
          // else return the fairs_per_page_* value (approved)
          : this.$config['fairs_per_page_' + this.fairs_view])
        // else (nothing of the above matches) return default fallback
        : this.$config.fairs_per_page
    },
    // fair items to display, based on filter, sorting and pagination
    prepared_fairs() {
      return this.paginate(this.filteredFairs(this.sortedFairs(this.state.fairs.slice(0))), this.fairs_per_page, this.page)
    },
    // total fair items to display, based on filter only
    filtered_fairs() {
      return this.filteredFairs(this.state.fairs.slice(0))
    }
  },
  created() {
    // recalculate height after a logo/image is loaded
    this.$eventBus.$on('fair-image-loaded', () => {
      this.setHeight()
    })
    // recalculate height after viewport is changed
    window.addEventListener('resize', () => {
      this.setHeight()
    })
  },
  mounted() {
    this.setHeight()
    // recalculate height after all logos/images are loaded
    window.addEventListener('load', () => {
      this.setHeight()
    })
  },
  methods: {
    // sort fair items based on sort criterion, using date_from property
    // 'oldest' - oldest first, newest last or
    // 'newest' - newest first, oldest last
    sortedFairs(fairs) {
      // skip if no fairs
      if (!fairs.length) return []
      return fairs.sort((a, b) => {
        if (this.state.sort === 'oldest') {
          // return (this.$moment(a.date_from) > this.$moment(b.date_from)) ? 1 : ((this.$moment(a.date_from) < this.$moment(b.date_from)) ? -1 : 0)
          return (this.$moment(a.sort_date) > this.$moment(b.sort_date)) ? 1 : ((this.$moment(a.sort_date) < this.$moment(b.sort_date)) ? -1 : 0)
        } else {
          // return (this.$moment(a.date_from) < this.$moment(b.date_from)) ? 1 : ((this.$moment(a.date_from) > this.$moment(b.date_from)) ? -1 : 0)
          return (this.$moment(a.sort_date) < this.$moment(b.sort_date)) ? 1 : ((this.$moment(a.sort_date) > this.$moment(b.sort_date)) ? -1 : 0)
        }
      })
    },
    // filter fair items based on current application filters
    filteredFairs(fairs) {
      // skip if no fairs
      if (!fairs.length) return []
      // simple validation
      // if no filters, return all fairs and skip
      if (!this.state.filters || !this.state.active_filters) return fairs.filter()
      // keep scope
      var vm = this
      return fairs.filter(fair => {
        var match = true
        // check for a match agains any of the current application filters
        for (var filter_id in vm.state.filters) {
          if (vm.state.active_filters[filter_id].length) {
            match = match & (typeof fair[vm.state.filters[filter_id].property] !== 'undefined' && vm.state.active_filters[filter_id].filter(v => fair[vm.state.filters[filter_id].property].indexOf(v) !== -1).length > 0)
          }
        }
        return match
      })
    },
    // slice portion of fair items based on the per page value for the current fairs view and the current page
    paginate(fairs, fairs_per_page, page) {
      // one page at a time
      // return fairs.slice((page - 1) * fairs_per_page, page * fairs_per_page)
      // OR
      // increment number of show fairs with one page
      return fairs.slice(0, page * fairs_per_page)
    },
    // recalculate the container height, needed for the transition animation
    setHeight(view) {
      view = view ? view : this.fairs_view
      // idea from https://snipcart.com/blog/vuejs-transitions-animations
      this.style.height = (this.$refs['fairs_view_' + view] && this.$refs['fairs_view_' + view].$el ? this.$refs['fairs_view_' + view].$el.clientHeight : this.style.height) + 'px'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style module lang="scss">
@import '~@/styles/vars';

.fairs {
  overflow: hidden;
  position: relative;
  padding: 22px 0;

  .fairs_view {
    position: absolute;
    width: 100%; // IE11
  }
}
.button {
  border: none;
  background-color: $primary-color;
  color: $secondary-color;
  padding: 14px 12px;
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    background-color: $primary-color-hover;
  }
}
</style>

<style scoped lang="scss" name="transitions">
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-leave, .fade-enter-to {
  opacity: 1;
}
</style>
