// Display fairs in List view

<template>
  <div class="fairs_list">
    <ul :class="$style.list">
      <li :class="$style.item" v-for="(fair, key) in fairs" :key="key">
        <ListItem :fair="fair"/>
      </li>
    </ul>
  </div>
</template>

<script>
import ListItem from './ListItem.vue'

export default {
  name: 'FairsList',
  components: {
    ListItem
  },
  props: [
    // prepared fairs to display, sorted, filtered and paginated
    'fairs'
  ]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style module lang="scss">
.list {
  list-style: none;
  padding: 0;
  margin: 0 0 22px;
}
.item {
  padding: 10px 0;
  border-bottom: 1px solid #f1f1f1;
  text-align: left;
}
</style>
