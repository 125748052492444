import axios from 'axios'
import config from '@/config'
import { Event } from '@/common/models'

export default {
  getEvents,
}

const { api } = config
const apiClient = axios.create({
  ...api.request,
  baseURL: `${api.basePath}/${api.lang ? api.lang : ''}`,
})

function getEvents(portfolio) {
  const url = 'events' + (portfolio ? `?filter=portfolio(${portfolio})` : '')
  return apiClient
    .get(url)
    .then(resp => {
      return resp.data.data.map(Event)
    })
    .catch((error) => {
      throw new Error(`[API]: getEvents failed: ${error}`)
    })
}
