// fairs Grid Item

<template>
  <transition :name="grid_view=='full'?'fade':''">
    <div class="fair_grid_item" :class="grid_view">
      <div class="logo">
        <i v-if="fair.icon && fair_image_loading" class="fair-image-loading icon-spin5 animate-spin"></i>
        <img v-if="fair.icon" :src="fair.icon" :alt="fair.title || fair.name + ' logo'" @load="$eventBus.$emit('fair-image-loaded'); fair_image_loading = false"/>
      </div>
      <div class="content">
        <h3 class="title">{{ (fair.title || fair.name) | spaceAfterComma }}</h3>
        <p class="location"><span class="city">{{ fair.city }}</span><span v-if="fair.city && fair.country">, </span><span class="country" v-if="fair.country">{{ fair.country }}</span></p>
        <p v-if="fair.date_to && fair.date_from" class="dates">
          {{ fair.date_from | moment("DD.MM.YYYY") }}<span v-if="fair.date_to && $moment(fair.date_to).format('x') > $moment(fair.date_from).format('x')"> - {{ fair.date_to | moment("DD.MM.YYYY") }}</span><br/>
          <ICalButton :fair="fair" type="iCal" :text="$root.l10n('ui.btn_iCal')"/>
          <ICalButton :fair="fair" type="Outlook" :text="$root.l10n('ui.btn_Outlook')"/>
        </p>
        <p v-else class="dates">
          {{ fair.period }}
        </p>
        <p class="subtitle">{{ fair.subtitle }}</p>
        <p class="__hint" v-if="$isDevelopmentEnv">
          branch: {{ fair.branch }}<br/>
          focus: {{ fair.focus }}<br/>
          continent: {{ fair.continent }}</br>
          period: {{ fair.period }}</br>
          sort_date: {{ fair.sort_date }}
        </p>
      </div>
      <div class="more_info">
        <span class="arrow left"></span><span class="arrow right"></span>
        <a v-if="moreinfoURL" :href="moreinfoURL | ensureHTTP" :target="isExternal ? '_blank' : '_self'">{{ $root.l10n('ui.btn_more_info') }}</a>
      </div>
    </div>
  </transition>
</template>

<script>
import ICalButton from '@/components/ICalButton.vue'

export default {
  name: 'GridItem',
  components: {
    ICalButton
  },
  data() {
    return {
      // flag if logo image is loading => show spinner animation
      fair_image_loading: true
    }
  },
  props: [
    // fair item to display
    'fair',
    // grid item view (based on application view, "full" or "lite"), used for transition animation some styling
    'grid_view'
  ],
  computed: {
    moreinfoURL() {
      // return this.fair['website']
      return this.fair[this.$config.moreinfo] || ''
    },
    isExternal() {
      return !this.$config.moreinfoInternal
    },
  },
}
</script>

<style scoped lang="scss">
@import '~@/styles/vars';

$grid-cell-background: #f9f9f9;

.fair_grid_item {
  min-height: 100%;
  position: relative;
  border-top: 8px solid $accent-color;
  // padding: 22px 0 45px;
  // padding: 22px 0 3em;
  padding: 22px 0 6em;
  overflow: hidden; // logo shadow
  background-color: $grid-cell-background;
  color: #213658;

  .content {
    padding: 0 12px;
  }

  h2, h3, h4, h5, h6, p {
    margin: 6px 0;
  }

}

.logo {
  width: calc(110 / 250 * 100%);
  min-height: 65px;
  max-width: 120px;
  float: right;
  background-color: #ffffff;
  line-height: 1px;
  margin-left: 10px;
  position: relative; // shadow
  z-index: 1;

  .icon-spin5 {
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    margin-top: -12px;
    z-index: 1;
  }
  img {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 2;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    z-index: -1;
  }
  &:after {
      position: absolute;
      z-index: -2;
      content: "";
      bottom: 17px;
      right: -5px;
      left: auto;
      width: 90%;
      top: 66%;
      max-width: 300px;
      box-shadow: 0 15px 12px rgba(0, 0, 0, 0.5);
      transform: rotate(5deg);
  }
}
.title {
  line-height: 1.2rem !important;
  font-size: 1rem;
  min-height: 2.4rem;
  word-break: break-word;
}
.location {
  font-size: calc(10 / 16 * 1rem);
}
.dates {
  clear: both;
  padding-top: 22px;
  a {
    color: inherit;
    font-size: calc(14 / 16 * 1rem);
  }
  i {
    color: $primary-color-alt;
    font-size: small;
    margin-right: 6px;
  }
  .calendar {
    display: inline-block;
    margin-right: 18px;
  }
}
.subtitle {
  padding-top: 11px;
  font-size: calc(14 / 16 * 1rem);
}
.more_info {
  bottom: 0;
  position: absolute;
  width: 100%;
  background-color: $primary-color-alt;
  height: 42px;

  // shadow
  overflow: hidden;

  .arrow {
    display: block;
    position: absolute;
    top: -20px;
    width: 50.1%;
    height: 20px;
    overflow: hidden;
    pointer-events: none;

    &::before, &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
    }
    &::before {
      z-index: 2;
      background: $grid-cell-background;
      transform: translateY(-10px);
    }
    // dark shadow
    &::after {
      z-index: 1;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    }

    &.left {
      left: 0;

      transform-origin: top left;
      transform: skewY(3deg) translateY(10px);

      &::after {
        transform-origin: bottom right;
        transform: skewY(3deg) translateY(-10px);
      }
    }
    &.right {
      right: 0;

      transform-origin: top right;
      transform: skewY(-3deg) translateY(10px);

      &::after {
        transform-origin: bottom left;
        transform: skewY(-3deg) translateY(-10px);
      }
    }

  }

  a {
    display: block;
    color: $secondary-color-alt;
    padding: 12px;
    text-align: center;

    &:hover {
      background-color: $primary-color-alt-hover;
    }
  }
}

// bottom-align dates
.fair_grid_item {
  .dates {
    position: absolute;
    bottom: 3em;
    height: 3em;
    line-height: 1.4em;
    padding: 0;
    margin: 0;
  }
}

@media screen and (max-width: 39.9375em) {
  .more_info {
    .arrow {
      &.left {
        transform: skewY(2deg) translateY(10px);
        &::after {
          transform: skewY(2deg) translateY(-10px);
        }
      }
      &.right {
        transform: skewY(-2deg) translateY(10px);
        &::after {
          transform: skewY(-2deg) translateY(-10px);
        }
      }
    }
  }
}
</style>
