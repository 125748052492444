import config from '@/config'

export function Event({
  name = '',
  title1 = '',
  title2 = '',
  title3 = '',
  title4 = '',
  title5 = '',
  city = '',
  country = '',
  date_from = '',
  date_to = '',
  icon = '',
  opening_hours_text = '',
  website = '',
  website_internal = '',
  continent = '',
  year = '',
  focus = [],
  eventType = [],
  family = [],
  sort_date = '',
  period = '',
}) {
  icon = `${config.assets_url}${icon ? icon : 'dummy.gif'}`

  return {
    name,
    title1,
    title2,
    title3,
    title4,
    title5,
    city,
    country,
    date_from,
    date_to,
    icon,
    opening_hours_text,
    website,
    website_internal,
    continent,
    year,
    focus,
    eventType,
    family,
    sort_date,
    period,
  }
}
