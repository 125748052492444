import _ from 'lodash'

let defaultConfig = {
  // default values, can be overriden like in index.html
  api: {
    basePath: '/data/rest.json', // only for dev
    queryPath: '',
    request: {
      timeout: 15000,
    },
  },

  theme: {
    colors: {
      primary: '#ef9514',
      secondary: '#1e428f',
    }
  },

  // extract filters from fiars data or use predefined ones
  extract_filters: true,
  // hide "reset" and "apply" buttons for Filter Box
  instant_filters: true,
  // show this much fairs per page
  fairs_per_page: 3, // fallback
  fairs_per_page_grid: 3, // for Grid view, including lite version
  fairs_per_page_list: 6, // for List view
  // select which item property will be used for "more info" link URL
  moreinfo: 'website', // defaults to 'website', can be any propery from API
  moreinfoInternal: false, // open 'more info' links into current window/tab (implies target="_blank"), defaults to FALSE => external links

  // URL for the external assets
  assets_url: 'http://www.messe-duesseldorf.de/VIS/messelogos/',

  // filter IDs must be in the format [property: String] - array of strings (object property name to look for in the REST API JSON data array)
  // we use this if extract_filters is ON and no "filters" is provided by the REST API JSON
  default_filter_ids: [
    'continent',
    'focus',
  ],
  // filters must be in the format [{property: String, values: Array of Strings}]
  // we use this if extract_filters is OFF and no "filters" is provided by the REST API JSON
  default_filters: [
    {
      property: 'continent',
      values: [
        'Asien',
        'Nordamerika',
      ]
    },
    {
      property: 'focus',
      values: [
        'Metallurgie',
        'Pipeline',
      ]
    }
  ]
}

// fixed Config that cannot be overriden by the application settings
let fixedConfig = {

  // fix allowed fairs_per_page_grid config values
  allowed_fairs_per_page_grid: [3, 6, 9, 'all'],
  // fix allowed filter_ids config values
  allowed_filter_ids: ['continent', 'country', 'year', 'eventType', 'family', 'focus']

}

const appConfig = _.get(window, 'DIMEDIS.fairfinder2.config', {})
const theme = _.get(window, 'DIMEDIS.theme', {})
const config = _.merge({},
  defaultConfig,
  appConfig,
  { theme },
  fixedConfig
)

export default config
