// Application bootstrap

<template>
  <div class="fair-finder2">
    <h1 v-if="!$config.api.basePath">Please set up API basePath</h1>
    <Main v-else :view="configuration.view" :filter="configuration.filter"/>
  </div>
</template>

<script>
import Main from './components/Main.vue'
import { store } from '@/store'
import api from '@/common/api'

export default {
  name: 'FairFinder',
  components: {
    Main
  },
  props: [
    'lang',
    'view',
    'filter'
  ],
  data() {
    return {
      // fallback configuration
      configuration: {
        lang: 'de', // fallback if not already instantiated in 'main.js'
        view: 'full',
        filter: ''
      },
      // using store pattern from store.js
      state: store.state
    }
  },
  created() {
    this.initConfiguration()
  },
  mounted() {
    this.initTheme()
    // load fair data from external REST API
    this.loadData()
  },
  watch: {
    'state.filters': function() {
      // clear all selected filters on fairs change
      store.clearActiveFilters()
    }
  },
  methods: {
    initConfiguration() {
      // replace fallback with $config-values if available
      this.configuration.lang = this.lang && this.lang
      this.configuration.view = this.view && this.view
      this.configuration.filter = []
      if (this.configuration.view === 'lite') {
        try {
          let filter = this.filter && JSON.parse(this.filter.replace(/'/g, '"'))
          this.configuration.filter = filter.constructor && filter.constructor === Array ? filter : (typeof filter === 'object' ? [filter] : [])
        } catch (error) {
          console.debug(error)
        }
      }
    },
    initTheme() {
      const currentScope = Array.from(this.$el.attributes).filter(attr => {
        return attr.name.startsWith('data-v')
      })[0].name
      // add to <head> with a namespace 'fairfinder2'
      const style = document.createElement('style')
      style.innerHTML = `
        [${currentScope}] .bg-color--primary {
          background-color: ${this.$config.theme.colors.primary};
        }
        [${currentScope}] .bg-color--secondary {
          background-color: ${this.$config.theme.colors.secondary};
        }
      `
      const parent = this.$el
      parent.insertBefore(style, parent.firstChild)
    },
    // load data from external REST API JSON
    loadData() {
      api.getEvents(this.$config.api.portfolio)
        .then((events) => {
          // TODO: add validation for wrong data type and missing data

          // load fairs
          var now = new Date()
          events = events.filter(event => now <= new Date(event.date_to))
          store.setFairs(events.slice())

          // load filters
          var filters
          if (this.$config.extract_filters) {
            // try to get filters' IDs from REST API JSON, then fallback to predefined ones OR default upes from config.js
            filters = events.filters || this.$config.filter_ids || this.$config.default_filter_ids
            // extract filters from fairs data based on filter's IDs
            this.extractFilters(this.state.fairs, filters)
          } else {
            // try to get filters from REST API JSON, then fallback to predefined ones OR default upes from config.js
            filters = events.filters || this.$config.filters || this.$config.default_filters
            store.setFilters(filters.slice())
          }
        })
        .catch(error => {
          throw new Error(error)
        })
    },
    // extract all possible filters from fairs
    extractFilters(fairs, filters) {
      if (this.$config.allowed_filter_ids) {
        // filter out not allowed filters
        filters = filters.filter(filter => {
          return this.$config.allowed_filter_ids.includes(filter)
        })
      }
      // filters are in the format [{property: String, values: Array of Strings}]
      filters = filters.map(filter => {
        return {
          property: filter,
          // values: Array.from(new Set(fairs.map(a => a[filter]).flat())).filter(Boolean).sort()
          // Edge does not support Array.prototype.flat() yet so we're using a workaroung
          values: Array.from(new Set(fairs.map(a => a[filter]).reduce((acc, val) => acc.concat(val), []))).filter(Boolean).sort()
        }
      })
      // filter out empty filters
      filters = filters.filter(filter => {
        return filter.values.length
      })
      store.setFilters(filters)
    }
  }
}
</script>

<style lang="scss" name="global">
@import "~@/styles/fonts";
@import "~@/styles/common";
</style>

<style scoped lang="scss">
.fair-finder2 {
  // font-family: 'Avenir', Helvetica, Arial, sans-serif;
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

<style lang="scss" name="transitions">
// fade
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

// slide
.slide-enter-active, .slide-leave-active {
  transition: transform .4s;
}
.slide-enter, .slide-leave-to {
  transform: translateX(-100%);
}
.slide-leave, .slide-enter-to {
  transform: translateX(0);
}

// fade-slide
.fade-slide-enter-active, .fade-slide-leave-active {
  transition: opacity .5s, transform .6s;
}
.fade-slide-move {
  transition: transform .2s;
}
.fade-slide-enter {
  transform: translateX(-100%);
  opacity: 0;
}
.fade-slide-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
.fade-slide-leave, .fade-slide-enter-to {
  transform: translateX(0);
  opacity: 1;
}
</style>
