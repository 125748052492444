exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.Fairs_fairs_1Vf87{overflow:hidden;position:relative;padding:22px 0\n}\n.Fairs_fairs_1Vf87 .Fairs_fairs_view_O3IbB{position:absolute;width:100%\n}\n.Fairs_button_72zsc{border:none;background-color:#20458d;color:#eff0ec;padding:14px 12px;border-radius:2px;cursor:pointer\n}\n.Fairs_button_72zsc:hover{background-color:#2959b7\n}", ""]);

// exports
exports.locals = {
	"fairs": "Fairs_fairs_1Vf87",
	"fairs_view": "Fairs_fairs_view_O3IbB",
	"button": "Fairs_button_72zsc"
};