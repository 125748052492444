exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.Grid_row_1iCNY{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-left:-10px;text-align:left\n}\n.Grid_cell_12Ng8{width:calc(33.33333% - 10px);margin-left:10px;margin-bottom:22px\n}\n@media screen and (max-width:39.9375em){\n.Grid_cell_12Ng8{width:calc(100% - 10px)\n}\n}", ""]);

// exports
exports.locals = {
	"row": "Grid_row_1iCNY",
	"cell": "Grid_cell_12Ng8"
};