export const store = {
  state: {
    // fairs to display, loaded from REST API JSON
    fairs: [],
    // filters to display in Filter Box, loaded from REST API JSON
    filters: {},
    // currently selected filters to filter fairs by
    active_filters: [],
    // sorting order for fairs:
    // 'oldest' - oldest first, newest last or
    // 'newest' - newest first, oldest last
    sort: 'oldest'
  },
  setFairs (fairs) {
    this.state.fairs = fairs.map((a, idx) => { a.idx = idx; return a })
  },
  setFilters (filters) {
    this.state.filters = filters
  },
  setActiveFilters (activeFilters) {
    this.state.active_filters = activeFilters
  },
  clearActiveFilters () {
    this.state.active_filters = this.state.filters.map(() => [])
  },
  switchSort (sort) {
    this.state.sort = sort || (this.state.sort === 'newest' ? 'oldest' : 'newest')
  }
}
