// http://kazupon.github.io/vue-i18n/guide/started.html#javascript

export const messages = {
  en: {
    ui: {
      btn_filter: 'Show Filter',
      btn_sort: 'Sort',
      opt_sort_newest: 'Latest first',
      opt_sort_oldest: 'Earliest first',
      btn_filter_show: 'Show Filter',
      btn_filter_hide: 'Hide Filter',
      btn_grid: 'Grid',
      btn_list: 'List',
      btn_reset_filters: 'reset to default',
      btn_apply_filters: 'Apply Filter',
      btn_load_more: 'load more fairs',
      btn_more_info: 'more info',
      btn_all: 'All',
      btn_iCal: 'iCal',
      btn_Outlook: 'Outlook'
    },
    mocks: {
      label_ical_hours: 'Opening Hours',
      label_ical_days_tue_thu: 'Tue - Thu',
      label_ical_days_fri: 'Fri'
    },
    filters: {
      // possible values: continent, country, year, ???, family, focus
      region: 'Region/Continent', // old
      regions: 'Region/Continent', // old
      continent: 'Region/Continent',
      country: 'Country',
      year: 'Year',
      eventType: 'Event Type',
      family: 'Family/Group',
      branch: 'Industry Focus', // old
      branches: 'Industry Focus', // old
      focus: 'Industry Focus'
    }
  },
  de: {
    ui: {
      btn_filter: 'Filter einblenden',
      btn_sort: 'Sortierung',
      opt_sort_newest: 'Späteste zuerst',
      opt_sort_oldest: 'Früheste zuerst',
      btn_filter_show: 'Filter einblenden',
      btn_filter_hide: 'Filter ausblenden',
      btn_grid: 'Grid',
      btn_list: 'List',
      btn_reset_filters: 'zurücksetzen',
      btn_apply_filters: 'Filter anwenden',
      btn_load_more: 'weitere Messen laden',
      btn_more_info: 'mehr Infos',
      btn_all: 'Alle',
      btn_iCal: 'iCal',
      btn_Outlook: 'Outlook'
    },
    mocks: {
      label_ical_hours: 'Öffnungszeiten',
      label_ical_days_tue_thu: 'Di - Do',
      label_ical_days_fri: 'Fr'
    },
    filters: {
      // possible values: Region/Kontinent, Land, Jahr, Messeart, Familie/Gruppe, Branchenfokus
      region: 'Region/Kontinent', // old
      regions: 'Region/Kontinent', // old
      continent: 'Region/Kontinent',
      country: 'Land',
      year: 'Jahr',
      eventType: 'Messeart',
      family: 'Familie/Gruppe',
      branch: 'Branchenfokus', // old
      branches: 'Branchenfokus', // old
      focus: 'Branchenfokus'
    }
  }
}
