// Display fairs in Grid view

<template>
  <div class="fairs_grid">
    <div :class="$style.row">
      <div :class="$style.cell" v-for="fair in fairs" :key="fair.idx">
        <GridItem :fair="fair" :grid_view="grid_view"/>
      </div>
    </div>
  </div>
</template>

<script>
import GridItem from './GridItem.vue'

export default {
  name: 'FairsGrid',
  components: {
    GridItem
  },
  props: [
    // prepared fairs to display, sorted, filtered and paginated
    'fairs',
    // grid item view (based on application view, "full" or "lite")
    'grid_view'
  ]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style module lang="scss">
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  text-align: left;
}
.cell {
  width: calc(33.3333333% - 10px);
  margin-left: 10px;
  margin-bottom: 22px;

  @media screen and (max-width: 39.9375em) {
    width: calc(100% - 10px);
  }
}
</style>
